body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

p {
    margin: 0;
}

* {
    font-family: Montserrat;
    box-sizing: border-box;
}

button {
    cursor: pointer;
    user-select: none;
}

input, button{
    border: none;
    outline: none;

    &:active, &:focus {
        outline: none;
        border: none;
    }
}

:root {
    --cobalt: #23408f;
    --light-blue-grey: #c8cfe3;
    --bluey-grey: #7c8dbc;
    --blueberry: #4d339f;
    /* --pale-lilac: #dedbf0; */
    --brown-grey: #808080;
    --brown-grey-light: #b1b1b1;
    --very-light-pink: #e4e4e4;
    --white: #f7f7f7;
    --black: #3b3b3b;
    --dark-seafoam-green: #40b881;
    --pastel-red: #ef4744;
    --marigold: #fbc200;
    --pale-lilac: #e9e8f0;
    --lavender: #bfbad2;
    --wisteria: #958cb4;
    --greyish-purple: #76699e;
}

.hoverable {
    transition: opacity .3s ease;

    &:hover {
        opacity: .8;
    }
}

.Toastify__progress-bar {
    visibility: hidden;
}

.label-item-popover, .actor-item-popover, .scenario-item-popover {
    width: 500px;
    background-color: var(--white);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 18px;

    font-size: 12px;
    font-weight: 300;
    line-height: 1.67;
    color: var(--black);

    .label-item-state {
        display: flex;
        align-items: center;
    }

    .label-full-name, .label-item-state-name {
        font-weight: 400;
    }
    .label-full-name {
        line-height: 35px;
    }
}

.scenario-item-popover {

}

.actor-item-popover {
    width: 200px;
}

.logo-background {
    min-width: 245px;
    height: 125px;
    background-color: var(--cobalt);

    .company-logo {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.rc-tooltip {
    opacity: 1;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: var(--white);
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
    border-top-color: var(--white);
}

.rc-tooltip-inner {
    border: none;
    background: none;
    padding: 0;
}

.appear-enter, .appear-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

.appear-enter.appear-enter-active {
    animation-name: appear;
}

.appear-leave.appear-leave-active {
    animation-name: disappear;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes disappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}