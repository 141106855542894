#auth-page-container {
    flex: 1;
    display: flex;
    height: 100%;

    .image {
        flex: 0 1 100%;
        box-shadow: inset -15px 1px 29px 0 rgba(27, 53, 128, 0.77);
        background-image: linear-gradient(222deg, var(--cobalt), #001768);
        position: relative;
        z-index: 0;

        img {
            height: 600px;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .auth-form-container {
        display: flex;
        flex: 1 0 620px;
        justify-content: center;
        position: relative;
        z-index: 1;
    }
}

/* Portrait and Landscape */
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1){
        #auth-page-container {
            .image {
                flex-basis: 0%;
                opacity: .4;
            }
        }
}
